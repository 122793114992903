/*=========================================================================================
  File Name: moduleCountryMutations.js
  Description: Country Module Mutations
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

export default {
  ADD_ITEM(state, item) {
    state.Services.unshift(item)
  },
  SET_Services(state, Services) {
    debugger;
    try {
      //const parsedData = JSON.parse(countries);
      state.Services = Services;
      //state.OutbreakCountries =  countries;
    } catch (error) {
      console.error("Failed to parse Services data JSON:", error);
      state.Services = [];
    }
  },
  UPDATE_Service(state, Service) {
    debugger
    const ServiceIndex = state.Services.findIndex((p) => p.Id == Service.Id)
    Object.assign(state.Services[ServiceIndex], Service)
  },
  REMOVE_ITEM(state, itemId) {
      const ItemIndex = state.Services.findIndex((p) => p.Id == itemId)
      state.Services.splice(ItemIndex, 1)
  },
}
